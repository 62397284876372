const { resolve } = require('path')
const dayjs = require('dayjs')

const {
	MAX_TEMPLATE_LIMIT = 10,
	NO_TRAILING_SLASH = 'latest,start-your-business,grow-your-business,build-your-career,lead-your-team,find-a-solution',
} = process.env

const trailingSlashArray = NO_TRAILING_SLASH.split(',')

//need to be called in this way for browser
const GATSBY_SITE_HOST = process.env.GATSBY_SITE_HOST

exports.articles = ({ path, href, ...article }, template) => {
	const component = article.template === 'link_builder' ? 'linkBuilding' : 'index'
	const skipTOC = article.template === 'long_form'
	return {
		path,
		component: resolve(`src/templates/${template}/${component}.jsx`),
		context: {
			...article,
			slug: href,
			canonical: `${GATSBY_SITE_HOST}${path}`,
			skipTOC,
		},
	}
}

exports.categories = ({ path, href, ...category }, template) => {
	const component = `${template}/${category.template === 'bp-winter-v1' ? 'mega' : category.template}`

	const page = {
		path,
		component: resolve(`src/templates/${component}/index.jsx`),
		context: {
			...category,
			slug: href,
			canonical: `${GATSBY_SITE_HOST}${path}`,
		},
	}

	return page
}

exports.reviews = ({ path, href, ...review }, template) => {
	const page = {
		path,
		component: resolve(`src/templates/${template}/index.jsx`),
		context: {
			...review,
			slug: href,
			canonical: `${GATSBY_SITE_HOST}${path}`,
		},
	}

	return page
}

exports.hubs = async (hub, template, graphql) => {
	const { href, moduleRiverOfLinks } = hub
	let q, contentQuery
	if (moduleRiverOfLinks.searchTerms) {
		q = moduleRiverOfLinks.searchTerms
	} else {
		contentQuery = {
			bool: {
				should: [{ terms: { tags__primaryValue__keyword: href } }, { terms: { tags__value__keyword: href } }],
			},
		}
	}

	const context = { q, contentQuery }
	const component = resolve(`src/templates/${template}/index.jsx`)

	const query = `#graphql
		query($q: String, $contentQuery: ContentQuery) {
			contentPagination(
				page: 1
				perPage: 30
				q: $q
				query: $contentQuery
				sort: displayModified__desc
			) {
				pageInfo {
					currentPage
					pageCount
					itemCount
				}
			}
		}
	`
	let pagination
	if (graphql) {
		pagination = await graphql(query, context).then(({ data: { contentPagination } }) => contentPagination)
	} else {
		pagination = { pageInfo: { pageCount: 10 } }
	}

	const pages = []
	const { path } = hub
	const showLatestLink = hub.href !== 'latest'
	const newPath = trailingSlashArray.includes(href) ? `/${href}` : path
	const pageData = {
		path: newPath,
		component,
		context: {
			...context,
			props: hub,
			type: 'hub',
			showLatestLink,
			pagination,
			canonical: `${GATSBY_SITE_HOST}${newPath}`,
		},
	}

	if (graphql) {
		pages.push(pageData)
		for (let page = 2; page <= pagination.pageInfo.pageCount && page <= MAX_TEMPLATE_LIMIT; page++) {
			const newPageData = { ...pageData, path: `${path}${page}/`, context: { ...pageData.context, page } }
			pages.push(newPageData)
		}
		return Promise.resolve(pages)
	}

	return pageData
}

exports.sitemap = ({ _id }) => {
	const [year, month] = _id.split('-')
	const startDate = dayjs(`${year}-${month}-01`, 'YYYY-MM-DD')
	const endDate = startDate.add(1, 'month')
	return {
		path: `/sitemap/${year}/${month}/`,
		component: resolve(`src/templates/sitemap/index.jsx`),
		context: {
			startDate: startDate.format('YYYY/MM'),
			endDate: endDate.format('YYYY/MM'),
		},
	}
}
